<template>
  <div class="immediately">
    <div class="content_con">
      <div class="name">{{ content.companyName }}</div>
      <div class="content">
        <i class="hy-icon-dianhua"></i>
        <div style="margin-left: 5px">{{ content.phone }}</div>
        <div style="margin-left: 20px">{{ content.concateUser }}</div>
      </div>
    </div>
    <div class="affirm">确认订单信息</div>
    <div class="item">
      <div class="item_top">
        <!-- <div><img src="../../../assets/fuwu/1.png" alt="" /></div> -->
        <div>
          <img
            :src="baseUrl + 'third/third/ossGetFile/' + content.mainGraph"
            alt=""
          />
        </div>
        <div class="item_content">
          <div style="font-size: 18px; font-weight: bold">
            {{ content.name }}
          </div>
          <div style="margin-top: 16px; color: #666">
            服务类型：{{ content.serviceType }}
          </div>
          <div style="margin-top: 10px; color: #666">数量：{{ num }}</div>
          <div style="margin-top: 10px; color: #666">
            单价：
            {{ content.price == null ? "面议" : content.price + content.unit }}
          </div>
        </div>
      </div>

      <div class="mony">
        <span>￥</span>
        <span class="mony_on">
          <!-- {{ num * content.price }}{{ content.unit }} -->
          {{
            content.price == null ? "面议" : num * content.price + content.unit
          }}
        </span>
      </div>
    </div>
    <div class="im_btn">
      <el-button class="hy-btn" @click="btnClick">提交订单</el-button>
    </div>
    <el-dialog
      title="支付"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="all">
        <div class="pice_item">
          <div class="item_title">需支付金额</div>
          <div class="item_input">
            <input
              type="text"
              placeholder="请输入需支付金额"
              v-model="paymentamount"
              maxlength="7"
              min="1"
              oninput='this.value=this.value.replace(/^[0]+[0-9]*$/gi,"")'
            />
          </div>
        </div>
        <!-- <div class="pice_item" style="margin-top: 20px">
          <div class="item_title">账户余额</div>
          <div>{{ balance }}元</div>
        </div> -->
        <div class="pice_item" style="margin-top: 20px">
          <div class="item_title">支付方式</div>
          <div>
            <el-radio
              style="margin-top: 10px"
              v-model.trim="radio"
              :label="item.bankType"
              border
              v-for="(item, index) in balance"
              :key="index"
            >
              <span v-if="item.bankType == 0">中信账户余额</span>
              <span v-if="item.bankType == 1">中金账户余额</span> (￥{{
                item.balance
              }})</el-radio
            >
            <!-- <el-radio  v-model="radio" label="1" border>中金账户余额(￥0)</el-radio> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibleClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    />
  </div>
</template>

<script>
import {
  serviceGooddetail,
  placeOtherOrder,
  payServerOrder,
} from "@/api/public";
// import { reqPayBond, reqCheckPassword } from "@/api/hy";
import md5 from "js-md5";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import { queryyue } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import Vue from "vue";
import { getAccountBalanceByParam } from "@/api/Accountopening";
export default {
  data() {
    return {
      radio: null,
      baseUrl: baseUrl,
      content: {},
      num: 1,
      centerDialogVisible: false,
      passWordIsShow: false,
      serviceOrderId: "",
      passWordIsShows: false,
      paymentamount: "",
      balance: [],
    };
  },
  mounted() {
    this.getdetails();
  },
  components: {
    InputPasswordDialog: () => import("@/components/InputPasswordDialog"),
  },
  methods: {
    centerDialogVisibleClick() {
      if (this.paymentamount == "") {
        this.$message.error("请输入需支付金额");
        return false; 
      }
      if (this.radio==null) {
        this.$message.error("请输入支付方式");
        return false; 
      }
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
      } else {
        this.earnest();
      }
    },
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.earnest();
      } else {
        alert(msg);
        return false;
      }
    },
    earnest() {
      let config = {
        serviceGoodId: this.$route.query.serviceGoodId,
        num: this.num,
        price: this.num * this.paymentamount,
      };
      placeOtherOrder(config).then((res) => {
        if (res.code == 0) {
          this.serviceOrderId = res.data;
          this.passWordIsShows = true;
          //  this.$message({
          //   type: "success",
          //   message: "支付成功",
          // });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
      // this.passWordIsShows = false;
    },
    submit(val) {
      let numpice = this.num * this.paymentamount;
      let config = {
        bankType: this.radio,
        serviceOrderId: this.serviceOrderId,
        payPassword: md5(val),
        payAmount: numpice,
      };
      payServerOrder(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "支付成功",
          });
          this.passWordIsShow = false;
          this.$router.push({
            path: "financial",
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    btnClick() {
      let config = {
        goodsId: this.$route.query.serviceGoodId,
        searchType: 2,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balance = res.data;
          this.centerDialogVisible = true;
        }else{
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    getdetails() {
      // queryyue().then((res) => {
      //   if (res.code == 0) {
      //     // this.ye
      //     this.balance = res.data;
      //   }
      // });

      let id = this.$route.query.serviceGoodId;
      serviceGooddetail(id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.content = res.data;
          if (res.data.price != null) {
            this.paymentamount = this.num * res.data.price;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.immediately {
  border: 1px solid #e5e5e5;
  width: 65%;
  min-width: 1000px;
  //   height: 100vh;
  background: white;
  margin: 20px auto;
  padding: 20px;
  .content_con {
    padding: 23px 17px;

    color: #666;
    font-size: 14px;
    .name {
      font-size: 18px;
      font-weight: bolder;
    }
    .content {
      margin-top: 16px;
      line-height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
      display: flex;
    }
  }
  .affirm {
    padding: 14px 15px;
    font-size: 16px;
    font-weight: bolder;

    border: 1px solid #e5e5e5;
    height: 45px;
    background: #f7f7f7;
  }
  .item {
    padding: 13px 12px;
    display: flex;
    justify-content: space-between;
    .item_top {
      display: flex;
      .item_content {
        margin-left: 20px;
      }
    }
    .mony {
      color: #f87811;
      .mony_on {
        font-size: 18px;
        font-weight: 400;
        font-weight: bolder;
      }
    }

    img {
      width: 121px;
      height: 121px;
    }
    display: flex;
  }
  .im_btn {
    text-align: center;
    button {
      width: 308px;
    }
  }
}
.all {
  margin: 0 auto;
  text-align: center;
  .pice_item {
    display: flex;
    align-items: center;
    .item_input {
      input {
        border-bottom: 1px solid #ccc;
      }
    }
    .item_title {
      width: 200px;
    }
  }
}
::v-deep .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0;
}
</style>